import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const loadTheme = async (brand: string) => {
  const theme = await import(`@/assets/styles/brands/${brand}/theme.ts`);
  return theme.default;
};

export const getVuetify = async () => {
  const theme = await loadTheme(import.meta.env.VITE_BRAND_THEME);

  return createVuetify({
    directives,
    components,
    theme: theme,
  });
};
