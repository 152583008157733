import { createRouter, createWebHistory } from 'vue-router';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import { RouteNames } from '@/types';

const routes = [
  {
    path: '/signin',
    name: RouteNames.SIGN_IN,
    meta: { title: 'SignIn' },
    component: () => import('./views/MainPage.vue'),
  },
  {
    path: '/signout',
    name: RouteNames.SIGN_OUT,
    meta: { title: 'SignOut' },
    component: () => import('./views/MainPage.vue'),
  },
  {
    path: '/signup',
    children: [
      {
        path: '',
        name: RouteNames.SIGN_UP,
        meta: { title: 'SignUp' },
        component: () => import('./views/MainPage.vue'),
      },
      {
        path: 'success',
        name: RouteNames.SIGN_UP_SUCCESS,
        meta: { title: 'Success' },
        component: () => import('./views/sign-up/SuccessPage.vue'),
      },
    ],
  },
  {
    path: '/error',
    name: RouteNames.ERROR,
    meta: { title: 'Error' },
    component: () => import('./views/ErrorPage.vue'),
  },
  {
    path: '/reset-password',
    name: RouteNames.RESET_PASSWORD,
    meta: { title: 'ResetPassword' },
    component: () => import('./views/MainPage.vue'),
  },
  {
    path: '/',
    redirect: '/signin',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (from?.query && !isEmpty(from.query) && !isEqual(from.query, to.query)) {
    next(merge(to, { query: from.query }));
  } else {
    next();
  }
});

export default router;
