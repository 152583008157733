export interface Store {
  email?: string;
  ownerCode?: string;
  customerId?: string;
  redirectURL?: string;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
}

export enum RouteNames {
  SIGN_UP = 'signUp',
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  RESET_PASSWORD = 'resetPassword',
  SIGN_UP_SUCCESS = 'signUpSuccess',
  ERROR = 'error',
}

export enum AuthStatus {
  CONFIGURING = 'configuring',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
}
