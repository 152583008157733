const setFavicon = () => {
  const favicon = document.createElement('link');
  favicon.setAttribute('rel', 'icon');
  favicon.setAttribute('type', 'image/x-icon');
  favicon.setAttribute(
    'href',
    `/${import.meta.env.VITE_BRAND_THEME}/favicon.ico`,
  );
  document.head.appendChild(favicon);
};

const setTitle = (app) => {
  document.title = app.config.globalProperties.$t(`app.title`);
};

export default {
  install: (app, options) => {
    setTitle(app);
    setFavicon();
  },
};
