<template>
  <v-app>
    <CDAHeader />
    <v-container fluid>
      <v-row>
        <v-col class="d-none d-md-flex">
          <ImageWithText
            class="my-auto"
            style="max-width: 380px"
            :image="{
              src: `/${brand}/left-side.png`,
              alt: brand,
              width: '240',
              height: '240',
            }"
            :title="$t('panel.left.title')"
            :description="$t('panel.left.description')"
          />
        </v-col>
        <v-col
          xs="12"
          md="6"
        >
        <Suspense>
          <router-view />
        </Suspense>
        </v-col>
      </v-row>
    </v-container>
    <Debug v-if="debug" />
  </v-app>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store/store';
import CDAHeader from '@/components/Header.vue';
import Debug from '@/components/Debug.vue';
import ImageWithText from '@/components/ImageWithText.vue';
import { useDatadog } from '@/composables/useDatadog';
import { useHead } from '@unhead/vue';

import('@/assets/styles/index.scss');

const router = useRouter();
const route = useRoute();
const { setData } = useStore();
useDatadog();

const debug = import.meta.env.VITE_DEBUG_MODE === 'true';
const brand = import.meta.env.VITE_BRAND_THEME;

const importCss = (brand: string) => {
  import(`@/assets/styles/brands/${brand}/index.scss`);
};

importCss(import.meta.env.VITE_BRAND_THEME);

useHead({
  title: 'amplify-auth',
  meta: [
    {
      name: 'robots',
      content: 'noindex, nofollow',
    },
  ],
});

onMounted(async () => {
  await router.isReady();

  if (route.query) {
    const email = route.query.email as string | undefined;
    const ownerCode = route.query.ownerCode as string | undefined;
    const customerId = route.query.customerId as string | undefined;
    const redirectURL = route.query.redirectURL as string | undefined;

    setData({ email, redirectURL, ownerCode, customerId });
  }
});
</script>
