<template>
  <div id="debug">
    <hr />
    <v-container>
      <h1 class="text-center">🚧 DEBUGGING 🚧</h1>
      <table class="mx-auto">
        <tr>
          <th>Item</th>
          <th>Description</th>
        </tr>
        <tr>
          <th>idToken</th>
          <th>{{ idToken }}</th>
        </tr>
        <tr>
          <th>accessToken</th>
          <th>{{ accessToken }}</th>
        </tr>
        <tr>
          <th>queryData</th>
          <th>{{ queryData }}</th>
        </tr>
        <tr>
          <th>route.name</th>
          <th>{{ route.name }}</th>
        </tr>
      </table>
    </v-container>
    <hr />
    <v-container>
      <h1 class="text-center">Documentation</h1>
      <div
        style="
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 14px;
          text-align: left;
        "
      >
        <table>
          <tr>
            <th>Item</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>Current route</td>
            <td>
              <p>{{ $route.name }}</p>
            </td>
          </tr>
          <tr>
            <td><p>Available routes</p></td>
            <td>
              <ul>
                <router-link
                  v-for="route in $router.options.routes"
                  :key="route.path"
                  :to="route.path"
                >
                  <li>{{ route }}</li>
                </router-link>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>How to pass data in URL:</p>
            </td>
            <td>
              <ul>
                <li>BASE_URL/signin/?q=<b>YOUR_STINGIFIED_JSON_DATA</b></li>
                <li>BASE_URL/signup?q=<b>YOUR_STINGIFIED_JSON_DATA</b></li>
                example:
                <code>BASE_URL/signup/?q={"username":"1251NA"}</code>
                <br />
                query is accessible in components via
                <code>route.query.q</code>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </v-container>
  </div>
</template>

<script setup>
// Global
import { onMounted, ref } from 'vue';
import { Auth, Hub } from 'aws-amplify';
import { useRoute } from 'vue-router';

// Data received from URL
const route = useRoute();
const queryData = route.query.q;

// Token
const idToken = ref('');
const accessToken = ref('');
const retrieveTokens = async () => {
  try {
    const session = await Auth.currentSession(); // Retrieves the current session
    idToken.value = session.getIdToken().getJwtToken(); // Gets the JWT token
    accessToken.value = session.getAccessToken().getJwtToken(); // Access token if needed
    // You can use these tokens as needed, for example, to make authenticated API requests
  } catch (error) {
    console.error('Error fetching tokens:', error);
  }
};

const listenForAuthEvents = () => {
  Hub.listen('auth', async (data) => {
    console.warn('AwsAuth listener:', data);
    if (data.payload.event === 'signIn') {
      const token = await retrieveTokens(); // Retrieve tokens after sign-in
      console.warn('AwsAuth token:', token);
    }
    if (data.payload.event === 'signOut') {
      idToken.value = '';
      accessToken.value = '';
    }
  });
};

onMounted(() => listenForAuthEvents());
</script>

<style scoped>
#debug {
  margin-top: 80px;
  background-color: lightgray;
}

table {
  width: 100%;
  max-width: 800px;
}
table,
th,
td {
  border: 1px solid rgb(225, 225, 225);
  padding: 5px;
  border-collapse: collapse;
}
</style>
