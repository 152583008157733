import { getCookieDomain } from './utils/domainConfig';

const awsExports = {
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_POOL_WEB_CLIENT_ID,
    // TODO: enabling cookie storage doesn't seem to be compatible with query redirection
    // Use the cookie storage later on once a solution is found for mobile for them to retrieve auth session through cookies
    // cookieStorage: {
    //   domain: getCookieDomain(
    //     import.meta.env.VITE_BRAND_NAME,
    //     import.meta.env.VITE_ENV,
    //   ),
    //   path: '/',
    //   expires: 365,
    //   secure: true,
    //   sameSite: 'strict',
    // },
  },
};

export default awsExports;
